import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#400d69';

const SpacelillyPage = ({ data }) => (
  <>
    <SEO
      title="Le meilleur casino NeoSurf dans l'univers 2022 – Space Lilly"
      description="Visite l’un des meilleurs casinos Neosurf en 2022 -  Casino SpaceLilly. Réclame le Paquet de bonus de bienvenu pour augmenter ton solde et obtenir des tours sans mise."
      alternateLanguagePages={[
        {
          path: 'casino/spacelilly',
          language: 'en'
        },
        {
          path: 'casino/spacelilly',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Le tout meilleur casino neosurf de l’Univers 2022 – SpaceLilly" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">LE MEILLEUR CASINO NEOSURF À L’UNIVERS 2022 – SPACE LILLY</h1>
            <p className="textColorLight"><em>Space Lilly est un Casino qui est tout simplement hors de ce monde!</em></p>
            <p className="textColorLight">Avec une interface étonnante sur le thème de l’espace qui suit l’héroïne de Space LILLY dans ses voyages à travers l’univers, c’est juste un casino amusant et polyvalent. La sélection de jeux est incroyable, offrant des certaines et des certaines Créneaux et de jeux de Table de dizaine de Fournisseurs de jeux et une vaste sélection de casinos en direct. Les bonus sont tout aussi incroyables, allant de divers bonus correspondants, des Tours Gratuits sans cagnotte aux remboursements avec cagnotte symbolique. Il n’y a vraiment rien à ne pas aimer chez LILLY!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">FORFAITS NEOSURF BONUS DE CASINO LILLY SPACE EN 2022</h2>
            <p>Le Casino Space Lilly accepte les paiements de Neosurf en 2022 ! Découvre les offres incroyables de l’un des casinos de Neosurf le mieux noté en 2022.</p>
            <h3 className="smaller textUppercase">PAQUET 1 DE BIENVENUE DE LILLY ÉTANT HORS DE CE MONDE</h3>
            <ul className="textColorDark">
              <li>Dépose pour la 1ʳᵉ fois et LILLY te récompensera de 100% jusqu’à 150 $ / € et elle lancera 25 tours gratuits sans mise!</li>
              <li>Dépose pour la 2e fois et LILLY te récompensera avec 20% jusqu’à 400 $ / € et elle lancera 50 tours gratuits sans mise!</li>
              <li>Dépose pour la 3ᵉ fois et LILLY te récompensera avec 25% jusqu’à 450 $ / € et elle lancera 125 tours gratuits sans mise!</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5" target="_blank" rel="noopener noreferrer">OUVRIR SPACE LILLY</a>
            </p>
            <h3 className="smaller textUppercase">PAQUET 2 DE BIENVENUE DE LILLY ÉTANT HORS DE CE MONDE</h3>
            <ul className="textColorDark">
              <li>Dépose pour la 1ʳᵉ fois et LILLY te récompensera de 20% jusqu’à 200 $ / € de remboursement gratuit!</li>
              <li>Dépose pour la 2e fois et LILLY te récompensera avec 20% jusqu’à 300 $ / € de remboursement gratuit!</li>
              <li>Dépose pour la 3ᵉ fois et LILLY te récompensera avec 25% jusqu’à 500 $ / € de remboursement gratuit!</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5" target="_blank" rel="noopener noreferrer">OUVRIR SPACE LILLY</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Forfait bonus de bienvenue disponible en 2022" />
        </div>
      </div>
    </div>
  </>
)

export default SpacelillyPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/spacelilly/neosurf_spacelilly.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/spacelilly/space-LILLY-EXPLORER-WELCOME-PACKAGE.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
